import Tokens from '../mobx/Tokens';
import Wallet from '../mobx/Wallet';
import BigNumber from "bignumber.js";
import { LiquidationModel, TokenModel } from '../Components/Models';


export default abstract class BaseProvider {
  public network: string;
  public platform: string;

  constructor(_network: string, _platform: string) {
    this.network = _network;
    this.platform = _platform;
  }

  public async liquidate(setCurrentAction: any, onClose: any, tokens: Tokens, wallet: Wallet, currentLiquidation: LiquidationModel, fromTokenAmount: BigNumber, toTokenAmount: BigNumber) {
    throw Error("Not implemented.");
  }

  public async autoLiquidate(setCurrentAction: any, onClose: any, tokens: Tokens, wallet: Wallet, currentLiquidation: LiquidationModel, fromTokenAmount: BigNumber, toTokenAmount: BigNumber) {
    throw Error("Not implemented.");
  }

  public async refresh(currentLiquidation: LiquidationModel) {
    throw Error("Not implemented.");
  }

  public async getToTokens(tokens: Tokens, currentLiquidation: LiquidationModel, tokenAmount: BigNumber): Promise<BigNumber> {
    throw Error("Not implemented.");
  }

  public async connectWallet(wallet: Wallet): Promise<[string, string]> {
    throw Error("Not implemented.");
  }

  public async checkWallet(wallet: Wallet) {
    throw Error("Not implemented.");
  }

  public async getUnderlyingTokenAmount(token: TokenModel, toTokenAmount: BigNumber): Promise<BigNumber> {
    throw Error("Not implemented.");
  }
}
