import { Anchor } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import Comptroller from './Comtroller';
import PoolContract from './PoolContract';
import LiqContract from './LiqContract';
import Tokens from '../../mobx/Tokens';
import Wallet from '../../mobx/Wallet';
import BigNumber from "bignumber.js";
import { LiquidationModel, TokenModel } from '../../Components/Models';
import Api from '../Api';
import BaseProvider from '../Provider';


const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
const USDT_ADDRESS = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

export default class TronProvider extends BaseProvider {
  constructor() {
    super("tron", "justlend")
  }

  public async autoLiquidate(setCurrentAction: any, onClose: any, tokens: Tokens, wallet: Wallet, currentLiquidation: LiquidationModel, fromTokenAmount: BigNumber, toTokenAmount: BigNumber) {
    setCurrentAction(0);
    const poolFromToken = tokens.getPoolToken(currentLiquidation!.from_amount.token);
    const toToken = tokens.getCommonToken(currentLiquidation!.to_amount.token);

    const shortfall = await Comptroller.getAccountLiquidity(currentLiquidation.borrower);
    if (shortfall.isZero()) {
      notifications.show({
        title: "Failed to run liquidation",
        message: "Liquidation for this borrow does not work at the moment",
        autoClose: true,
        color: "red"
      });
      return;
    }

    const assets = await Comptroller.getAssetsIn(currentLiquidation.borrower);
    if (assets.indexOf(currentLiquidation.to_amount.token.address) === -1) {
      notifications.show({
        title: "Failed to run liquidation",
        message: "Token is not authorized as collateral",
        autoClose: true,
        color: "red"
      });
      return;
    }

    let toContract = await PoolContract.getContract(currentLiquidation.to_amount.token.address)
    const balance = await toContract.balanceOf(currentLiquidation.borrower);
    if (balance.comparedTo(toTokenAmount) === -1) { // balance < toTokenAmount
      notifications.show({
        title: "Failed to run liquidation",
        message: "Collateral balance is not enough",
        autoClose: true,
        color: "red"
      });
      return;
    }

    setCurrentAction(1);

    let liqContract = await LiqContract.getContract()
    let usdtContract = await PoolContract.getContract(USDT_ADDRESS);
    const balanceAllowance = await usdtContract.allowance(wallet.address, liqContract.liqAddress);
    let usdtTokenAmount = 1_000_000_000_000_000;
    if (balanceAllowance.comparedTo(usdtTokenAmount) === -1) { // balanceAllowance < fromTokenAmount
      const approveTransId = await usdtContract.approve(liqContract.liqAddress, "115792089237316195423570985008687907853269984665640564039457584007913129639935");
      notifications.show({
        title: "Approve was executed",
        message: <Anchor target="_blank" href={"https://tronscan.io/#/transaction/" + approveTransId}>Open Transaction</Anchor>,
        autoClose: true,
        color: "green"
      });
      await delay(5000);
    }

    console.log(
      USDT_ADDRESS,
      poolFromToken.address,
      currentLiquidation.from_amount.token.address,
      currentLiquidation.to_amount.token.address,
      toToken.address,
      fromTokenAmount,
      currentLiquidation.borrower
    );

    const transId = await liqContract.liquidate(
      USDT_ADDRESS,
      poolFromToken.address,
      currentLiquidation.from_amount.token.address,
      currentLiquidation.to_amount.token.address,
      toToken.address,
      fromTokenAmount,
      currentLiquidation.borrower
    );
    notifications.show({
      title: "Liquidation was executed",
      message: <Anchor target="_blank" href={"https://tronscan.io/#/transaction/" + transId}>Open Transaction</Anchor>,
      autoClose: true,
      color: "green"
    });

    await delay(10000);

    setCurrentAction(3);

    await this.refresh(currentLiquidation);
    onClose(true);
  }

  public async liquidate(setCurrentAction: any, onClose: any, tokens: Tokens, wallet: Wallet, currentLiquidation: LiquidationModel, fromTokenAmount: BigNumber, toTokenAmount: BigNumber) {
    setCurrentAction(0);
    const poolToken = tokens.getPoolToken(currentLiquidation!.from_amount.token);

    const shortfall = await Comptroller.getAccountLiquidity(currentLiquidation.borrower);
    if (shortfall.isZero()) {
      notifications.show({
        title: "Failed to run liquidation",
        message: "Liquidation for this borrow does not work at the moment",
        autoClose: true,
        color: "red"
      });
      return;
    }

    const assets = await Comptroller.getAssetsIn(currentLiquidation.borrower);
    if (assets.indexOf(currentLiquidation.to_amount.token.address) === -1) {
      notifications.show({
        title: "Failed to run liquidation",
        message: "Token is not authorized as collateral",
        autoClose: true,
        color: "red"
      });
      return;
    }

    let toContract = await PoolContract.getContract(currentLiquidation.to_amount.token.address)
    const balance = await toContract.balanceOf(currentLiquidation.borrower);
    if (balance.comparedTo(toTokenAmount) === -1) { // balance < toTokenAmount
      notifications.show({
        title: "Failed to run liquidation",
        message: "Collateral balance is not enough",
        autoClose: true,
        color: "red"
      });
      return;
    }

    setCurrentAction(1);
    if (currentLiquidation.from_amount.token.symbol !== "TRX") {
      let fromContract = await PoolContract.getContract(currentLiquidation.from_amount.token.address);
      const balanceFromAmount = await fromContract.balanceOf(wallet.address);
      if (balanceFromAmount.comparedTo(fromTokenAmount) === -1) {  // balanceFromAmount < fromTokenAmount
        notifications.show({
          title: "Failed to run liquidation",
          message: "You do not have enough tokens",
          autoClose: true,
          color: "red"
        });
        return;
      }

      const balanceAllowance = await fromContract.allowance(wallet.address, poolToken.address);
      if (balanceAllowance.comparedTo(fromTokenAmount) === -1) { // balanceAllowance < fromTokenAmount
        const approveTransId = await fromContract.approve(poolToken.address, "115792089237316195423570985008687907853269984665640564039457584007913129639935");
        notifications.show({
          title: "Approve was executed",
          message: <Anchor target="_blank" href={"https://tronscan.io/#/transaction/" + approveTransId}>Open Transaction</Anchor>,
          autoClose: true,
          color: "green"
        });
        await delay(5000);
      }
    }

    setCurrentAction(2);
    let poolContract = await PoolContract.getContract(poolToken.address)
    const transId = await poolContract.liquidate(currentLiquidation.borrower, currentLiquidation.to_amount.token.address, fromTokenAmount);
    notifications.show({
      title: "Liquidation was executed",
      message: <Anchor target="_blank" href={"https://tronscan.io/#/transaction/" + transId}>Open Transaction</Anchor>,
      autoClose: true,
      color: "green"
    });

    await delay(10000);

    setCurrentAction(3);

    await this.refresh(currentLiquidation);
    onClose(true);
  }

  public async refresh(currentLiquidation: LiquidationModel) {
    await Api.refreshBorrower(currentLiquidation.borrower);
  }

  public async getToTokens(tokens: Tokens, currentLiquidation: LiquidationModel, tokenAmount: BigNumber): Promise<BigNumber> {
    const poolToken = tokens.getPoolToken(currentLiquidation.from_amount.token);
    const toTokens = await Comptroller.liquidateCalculateSeizeTokens(poolToken.address, currentLiquidation.to_amount.token.address, tokenAmount);
    return toTokens;
  }

  public async getUnderlyingTokenAmount(token: TokenModel, toTokenAmount: BigNumber): Promise<BigNumber> {
    const underlyingToken = await PoolContract.getContract(token.address);
    const exchangeRate = await underlyingToken.exchangeRateStored();
    const balance = toTokenAmount.multipliedBy(exchangeRate).dividedBy(10 ** 18);
    return balance;
  }

  public async connectWallet(wallet: Wallet): Promise<[string, string]> {
    if (window.tronWeb) {
      const res = await window.tronLink.request({method: 'tron_requestAccounts'});
      if (res.code === 200) { // User acceptance of authorization
        wallet.setAddress(window.tronWeb.defaultAddress.base58);
        return ["", ""];
      } else if (res.code === 4000) { // In the queue, no need to duplicate commits
        return ["", ""];
      } else if (res.code === 4001) {// User refusal to authorize
        return ["red", "REJECTED"]
      } else {
        return ["red", "Please login to TronLink extention wallet first."];
      }
    } else {
      //wallet is not detected at all
      return ["red", "WALLET NOT DETECTED"];
    }
  }

  public async checkWallet(wallet: Wallet) {
    if (window.tronLink && window.tronLink.ready) {
      wallet.setAddress(window.tronWeb.defaultAddress.base58);
    }
  }
}
